.infoView {
  width: fit-content;
  background-color: #e5ebf3;
  padding: 10px 20px;
  margin: 20px 0;
  font-size: 16px;
  display: flex;
  flex-wrap: nowrap;
  color: #303540;
  position: relative;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  transition: all 0.1s ease;
}
.infoView::before {
  content: '';
  border: 3.5px solid #1f56a0;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.57;
}
.infoView .infoView__text {
  text-align: left;
  margin-bottom: 0;
}
.infoView .infoView__children {
  text-align: left;
  margin-left: 10px;
  margin-right: -10px;
}
.infoView .infoView__children .button {
  padding: 5px 10px;
  box-shadow: 0px 2px 0 0 #cfd6e6;
}
.infoView .infoView__children .button:active {
  box-shadow: 0px 1px 0 0 #cfd6e6;
}
.infoView .infoView__icon {
  font-size: 40px;
  color: #1f56a0;
  line-height: 2;
  line-height: 1;
  width: 30px;
  height: 30px;
  font-size: 30px;
  margin-right: 13.33333333px;
}
.infoView--green {
  width: fit-content;
  background-color: #e7f0ec;
  padding: 10px 20px;
  margin: 20px 0;
  font-size: 16px;
  display: flex;
  flex-wrap: nowrap;
  color: #303540;
  position: relative;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  transition: all 0.1s ease;
}
.infoView--green::before {
  content: '';
  border: 3.5px solid #40ad36;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.57;
}
.infoView--green .infoView__text {
  text-align: left;
  margin-bottom: 0;
}
.infoView--green .infoView__children {
  text-align: left;
  margin-left: 10px;
  margin-right: -10px;
}
.infoView--green .infoView__children .button {
  padding: 5px 10px;
  box-shadow: 0px 2px 0 0 #cfd6e6;
}
.infoView--green .infoView__children .button:active {
  box-shadow: 0px 1px 0 0 #cfd6e6;
}
.infoView--green .infoView__icon {
  font-size: 40px;
  color: #1f56a0;
  line-height: 2;
  line-height: 1;
  width: 30px;
  height: 30px;
  font-size: 30px;
  margin-right: 13.33333333px;
}
.infoView--blue {
  width: fit-content;
  background-color: #e5ebf3;
  padding: 10px 20px;
  margin: 20px 0;
  font-size: 16px;
  display: flex;
  flex-wrap: nowrap;
  color: #303540;
  position: relative;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  transition: all 0.1s ease;
}
.infoView--blue::before {
  content: '';
  border: 3.5px solid #1f56a0;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.57;
}
.infoView--blue .infoView__text {
  text-align: left;
  margin-bottom: 0;
}
.infoView--blue .infoView__children {
  text-align: left;
  margin-left: 10px;
  margin-right: -10px;
}
.infoView--blue .infoView__children .button {
  padding: 5px 10px;
  box-shadow: 0px 2px 0 0 #cfd6e6;
}
.infoView--blue .infoView__children .button:active {
  box-shadow: 0px 1px 0 0 #cfd6e6;
}
.infoView--blue .infoView__icon {
  font-size: 40px;
  color: #1f56a0;
  line-height: 2;
  line-height: 1;
  width: 30px;
  height: 30px;
  font-size: 30px;
  margin-right: 13.33333333px;
}
.infoView--white {
  width: fit-content;
  background-color: #f3f5f8;
  padding: 10px 20px;
  margin: 20px 0;
  font-size: 16px;
  display: flex;
  flex-wrap: nowrap;
  color: #303540;
  position: relative;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  transition: all 0.1s ease;
}
.infoView--white::before {
  content: '';
  border: 3.5px solid #fff;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.57;
}
.infoView--white .infoView__text {
  text-align: left;
  margin-bottom: 0;
}
.infoView--white .infoView__children {
  text-align: left;
  margin-left: 10px;
  margin-right: -10px;
}
.infoView--white .infoView__children .button {
  padding: 5px 10px;
  box-shadow: 0px 2px 0 0 #cfd6e6;
}
.infoView--white .infoView__children .button:active {
  box-shadow: 0px 1px 0 0 #cfd6e6;
}
.infoView--white .infoView__icon {
  font-size: 40px;
  color: #1f56a0;
  line-height: 2;
  line-height: 1;
  width: 30px;
  height: 30px;
  font-size: 30px;
  margin-right: 13.33333333px;
}
.infoView--red {
  width: fit-content;
  background-color: #f1e9ed;
  padding: 10px 20px;
  margin: 20px 0;
  font-size: 16px;
  display: flex;
  flex-wrap: nowrap;
  color: #303540;
  position: relative;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  transition: all 0.1s ease;
}
.infoView--red::before {
  content: '';
  border: 3.5px solid #e64545;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.57;
}
.infoView--red .infoView__text {
  text-align: left;
  margin-bottom: 0;
}
.infoView--red .infoView__children {
  text-align: left;
  margin-left: 10px;
  margin-right: -10px;
}
.infoView--red .infoView__children .button {
  padding: 5px 10px;
  box-shadow: 0px 2px 0 0 #cfd6e6;
}
.infoView--red .infoView__children .button:active {
  box-shadow: 0px 1px 0 0 #cfd6e6;
}
.infoView--red .infoView__icon {
  font-size: 40px;
  color: #1f56a0;
  line-height: 2;
  line-height: 1;
  width: 30px;
  height: 30px;
  font-size: 30px;
  margin-right: 13.33333333px;
}
