.centeredView {
  flex-direction: column;
  background: inherit;
  align-items: center;
  justify-content: center;
}
.transactionOverview,
.accountChooser {
  max-width: 850px;
  margin: 0 auto;
}
.transactionOverview--transactionSelected {
  max-width: 850px;
  margin: 0 auto;
}
@media all and (min-width: 851px) {
  .transactionOverview--transactionSelected {
    max-width: 100%;
    padding-right: 50%;
  }
}
.transactionOverview--notLoggedIn {
  flex-basis: 0;
  flex-grow: 1;
  max-height: 93vh;
  overflow: auto;
  flex-direction: column;
  background: inherit;
  align-items: center;
  justify-content: center;
}
.accountChooser {
  padding-bottom: 2em;
}
.ant-table .ant-table-tbody tr,
.ant-table .ant-table-thead tr {
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.ant-table .ant-table-tbody tr th,
.ant-table .ant-table-thead tr th {
  background: transparent;
  color: #303540;
  opacity: 0.7;
}
.ant-table .ant-table-tbody tr th:hover,
.ant-table .ant-table-thead tr th:hover,
.ant-table .ant-table-tbody tr th:focus,
.ant-table .ant-table-thead tr th:focus {
  opacity: 1;
}
.ant-table .ant-table-tbody tr td,
.ant-table .ant-table-thead tr td {
  border-bottom: 0 !important;
  background: transparent;
  color: #303540;
  cursor: pointer;
}
.ant-table .ant-table-tbody tr td.transaction-list-name,
.ant-table .ant-table-thead tr td.transaction-list-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100vw;
}
.ant-table .ant-table-tbody tr td.transaction-list-name strong,
.ant-table .ant-table-thead tr td.transaction-list-name strong {
  text-overflow: ellipsis;
  overflow: hidden;
}
.ant-table .ant-table-tbody tr td.transaction-list-sum,
.ant-table .ant-table-thead tr td.transaction-list-sum {
  width: fit-content;
}
.ant-table .ant-table-tbody tr th.transaction-list-date,
.ant-table .ant-table-thead tr th.transaction-list-date,
.ant-table .ant-table-tbody tr td.transaction-list-date,
.ant-table .ant-table-thead tr td.transaction-list-date {
  width: 130px;
  white-space: nowrap;
}
.ant-table .ant-table-tbody tr th.transaction-list-status,
.ant-table .ant-table-thead tr th.transaction-list-status,
.ant-table .ant-table-tbody tr td.transaction-list-status,
.ant-table .ant-table-thead tr td.transaction-list-status {
  width: 160px;
}
.ant-table .ant-table-tbody tr td.transaction-list-chooseReceipt,
.ant-table .ant-table-thead tr td.transaction-list-chooseReceipt {
  cursor: unset;
}
.ant-table .ant-table-tbody tr.active-transaction-row td.transaction-list-name,
.ant-table .ant-table-thead tr.active-transaction-row td.transaction-list-name {
  white-space: unset;
  text-overflow: unset;
  overflow: unset;
}
.ant-table .ant-table-tbody tr.active-transaction-row td.transaction-list-name strong,
.ant-table .ant-table-thead tr.active-transaction-row td.transaction-list-name strong {
  text-overflow: unset;
  overflow: unset;
}
.ant-table .ant-table-tbody tr:hover {
  background-color: #cfd6e6;
}
.ant-table {
  display: block;
}
.ant-table .ant-table-tbody,
.ant-table .ant-table-thead {
  display: block;
}
.ant-table .ant-table-tbody tr,
.ant-table .ant-table-thead tr {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
}
.ant-table .ant-table-tbody tr td,
.ant-table .ant-table-thead tr td,
.ant-table .ant-table-tbody tr th,
.ant-table .ant-table-thead tr th {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: baseline;
}
.ant-table .ant-table-tbody tr td.transaction-list-name,
.ant-table .ant-table-thead tr td.transaction-list-name,
.ant-table .ant-table-tbody tr th.transaction-list-name,
.ant-table .ant-table-thead tr th.transaction-list-name {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 99;
}
@media all and (max-width: 850px) {
  .ant-table tr.active-transaction-row td.transaction-list-chooseReceipt {
    width: 100%;
    height: auto;
  }
}
.centeredComponent {
  align-self: center;
}
.ant-table-tbody > tr > td {
  background: transparent;
  border: 0 !important;
  height: auto;
}
.active-transaction-row {
  background: #fff !important;
}
.transaction-status {
  border-radius: 20px;
  padding: 2px 10px 2px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}
.transaction-status::before {
  content: '•';
  padding-right: 8px;
  margin-left: -10px;
  color: transparent;
  font-weight: bold;
}
.transaction-status--noEmailFound,
.transaction-status--emailsFound {
  background-color: #edf0f5;
}
.transaction-status--noEmailFound::before,
.transaction-status--emailsFound::before {
  color: #cfd6e6;
}
.transaction-status--notSearchedForEmails {
  background-color: #f1e3e6;
}
.transaction-status--notSearchedForEmails::before {
  color: #e64545;
}
.transaction-status--chosenReceipt {
  background-color: #dde4ef;
}
.transaction-status--chosenReceipt::before {
  color: #1f56a0;
}
.transaction-status--ignored::before {
  color: #cfd6e6;
}
.transaction-status--readyForExport {
  background-color: #e0ede5;
}
.transaction-status--readyForExport::before {
  color: #40ad36;
}
.transactionRow--ignored td.transaction-list-name,
.transactionRow--ignored td.transaction-list-date,
.transactionRow--ignored td.transaction-list-status,
.transactionRow--ignored td.transaction-list-sum {
  opacity: 0.5;
}
.ant-table-tbody > tr > td.transaction-list-chooseReceipt {
  padding: 0px 0px;
}
.ant-table-tbody > .active-transaction-row:hover > td {
  background: #fff;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: inherit;
}
.transactinListInfoviews {
  padding: 0 20px;
}
.transactionListHeader {
  flex: 0 1 auto;
}
.transactionListHeader .transactionListHeader__breadcrumb {
  display: block;
  padding: 0 20px;
  margin: 20px 0;
}
.transactionListHeader .transactionListHeader__breadcrumb .account__backToOtherAccounts {
  display: inline-block;
  text-align: left;
  padding: 0;
  border: 0;
  margin: 0;
  min-width: 0;
  height: auto;
  width: auto;
  font-size: 1em;
  background-color: transparent;
  border: 0px transparent;
  border-radius: 2px;
  padding: 0px 0.25em;
  margin-left: -0.25em;
  margin-right: -0.25em;
  box-shadow: none;
  color: #1f56a0;
}
.transactionListHeader .transactionListHeader__breadcrumb .account__backToOtherAccounts:hover {
  text-decoration: underline;
}
.transactionListHeader .transactionListHeader__breadcrumb .account__backToOtherAccounts .anticon,
.transactionListHeader .transactionListHeader__breadcrumb .account__backToOtherAccounts .icon {
  margin-right: -0.25em;
}
.transactionListHeader .transactionListHeader__breadcrumb .account__backToOtherAccounts:hover {
  color: #1f56a0;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  text-decoration: underline !important;
}
.transactionListHeader .transactionListHeader__breadcrumb .account__backToOtherAccounts:active {
  outline: none;
  border: none;
  text-decoration: underline;
  top: auto;
  box-shadow: 0 0 0 0 transparent;
}
.transactionListHeader .transactionListHeader__breadcrumb .account__backToOtherAccounts:focus {
  border: none;
  outline: none;
  color: #fff;
  background-color: #1f56a0;
}
.transactionListHeader .transactionListHeader__content {
  min-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: top;
  padding: 0 20px;
  flex: initial;
}
.transactionListHeader .transactionListHeader__content .transactionListHeader__account {
  flex: 50%;
}
.transactionListHeader .transactionListHeader__content .transactionListHeader__account .account {
  font-size: 100%;
  display: flex;
  flex-direction: column-reverse;
}
.transactionListHeader .transactionListHeader__content .transactionListHeader__account .account span {
  display: block;
}
.transactionListHeader .transactionListHeader__content .transactionListHeader__account .account .account__formalInformation {
  opacity: 0.5;
  font-size: 0.9em;
}
.transactionListHeader .transactionListHeader__content .transactionListHeader__account .account .account__name {
  margin-top: 4px;
  margin-bottom: -3px;
  font-weight: bold;
}
.transactionListHeader .transactionListHeader__content .rangepicker .ant-calendar-picker-input {
  background-color: transparent;
  display: inline-block;
  text-align: left;
  padding: 0;
  border: 0;
  margin: 0;
  min-width: 0;
  height: auto;
  width: auto;
}
.transactionListHeader .transactionListHeader__content .rangepicker__button {
  display: inline-block;
  text-align: left;
  padding: 0;
  border: 0;
  margin: 0;
  min-width: 0;
  height: auto;
  width: auto;
  font-size: 1em;
  background-color: transparent;
  border: 0px transparent;
  border-radius: 2px;
  padding: 0px 0.25em;
  margin-left: -0.25em;
  margin-right: -0.25em;
  box-shadow: none;
  color: #1f56a0;
}
.transactionListHeader .transactionListHeader__content .rangepicker__button:hover {
  text-decoration: underline;
}
.transactionListHeader .transactionListHeader__content .rangepicker__button .anticon,
.transactionListHeader .transactionListHeader__content .rangepicker__button .icon {
  margin-right: -0.25em;
}
.transactionListHeader .transactionListHeader__content .rangepicker__button:hover {
  color: #1f56a0;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  text-decoration: underline !important;
}
.transactionListHeader .transactionListHeader__content .rangepicker__button:active {
  outline: none;
  border: none;
  text-decoration: underline;
  top: auto;
  box-shadow: 0 0 0 0 transparent;
}
.transactionListHeader .transactionListHeader__content .rangepicker__button:focus {
  border: none;
  outline: none;
  color: #fff;
  background-color: #1f56a0;
}
.transactionListHeader .transactionListHeader__content .transactionListHeader__export {
  flex: 50%;
}
.transactionListHeader .export_transactions--status {
  display: block;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 0.9em;
  margin-bottom: 4px;
}
.fileupload {
  margin-top: 20px;
}
